@import './normalize.css';
@import './animate.css';
@import './toggle.css';
@import url('https://fonts.googleapis.com/css?family=Roboto');
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.search-content::-webkit-scrollbar,
.marker-popup.regular::-webkit-scrollbar {
  display: none;
}

*:focus {
  outline: none;
}

.search-or-clear,
.my-location {
  width: 70px;
}

.search-or-clear > svg,
.my-location > svg {
  position: relative;
  width: 100% !important;
}

html,
body,
root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: auto;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
  overflow: -moz-scrollbars-none;
  overscroll-behavior: contain !important;
  touch-action: none !important;
  touch-action: pan-down !important;
}

body {
  height: 100%;
  /* position: fixed; */
  -webkit-overflow-scrolling: touch;
  font-family: 'Roboto', sans-serif !important;
  overflow: hidden;
  font-size: 1em;
}

a.mapboxgl-ctrl-logo {
  display: none !important;
}

svg.loading,
.my-location.location_blink svg {
  -webkit-animation: rotation 1s infinite linear;
  animation: rotation 1s infinite linear;
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}

@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

img#current-location {
  height: 1.563em;
  width: 1.563em;
  -webkit-animation-name: pulse_animation;
  animation-name: pulse_animation;
  -webkit-animation-duration: 5000ms;
  animation-duration: 5000ms;
  -webkit-transform-origin: 70% 70%;
  transform-origin: 70% 70%;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

@-webkit-keyframes pulse_animation {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  30% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  40% {
    -webkit-transform: scale(1.08);
    transform: scale(1.08);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  60% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  70% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
  80% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes pulse_animation {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  30% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  40% {
    -webkit-transform: scale(1.08);
    transform: scale(1.08);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  60% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  70% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
  80% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

#close-icons > svg {
  height: 1.875em;
  width: 1.563em;
}

.cyclorama-loading {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 12000000000;
}

a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex],
div {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

.main-alert {
  right: 0 !important;
}

div.success-alert {
  background-color: #2c3e50;
}

div.info-alert {
  background-color: #2980b9;
}

div.error-alert {
  background-color: #2980b9;
}

.mapboxgl-canvas-polygon {
  cursor: url(../img/polygon_search.png) 0 30, auto !important;
}

.mapboxgl-canvas-pointer {
  cursor: url(../img/pointer_search.png) 15 30, auto !important;
}

/* This ugly hack is for IE */
.loadingImage {
  height: 5.5em;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.loadingContainer {
  height: 100vh;
  width: 100vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

div.twitter-picker:nth-child(3) :nth-child(8),
div.twitter-picker:nth-child(3) :nth-child(9) {
  display: none !important;
}

.main-disclaimer-overlay {
  position: absolute !important;
  z-index: 10000000000;
  top: 0;
}

.main-disclaimer {
  margin: 0;
  background-color: #ecf0f1;
}

.rt-resizable-header-content {
  font-size: 13px !important;
}

td.pivoted {
  text-align: center;
  color: #34495e !important;
  padding: 5px;
}

td.no-arrow.pivoted {
  color: grey;
}

td.description.pivoted {
  text-align: left;
}

th.description {
  text-align: left;
}

#streetview-logo-image {
  position: absolute;
  height: auto;
  width: 65px;
  /* height: 50px;
  width: 50px; */
  bottom: 10px;
  right: 10px;
}

.street-view-image {
  position: relative;
}
